import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Headline from '../../../components/ui/headline';
import { useLocale } from '../../../context/locale.context';
import { getCopy } from '../../../helpers/copy';
import Container from '../../../modules/container';
import Layout from '../../../modules/layout';
import * as styles from '../../../styles/newsletter.module.scss';

const NewsletterUnsubscribe = () => {
  const { locale } = useLocale();
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );
  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  return (
    <Layout
      title={getCopy('title.newsletterUnsubscribe', microcopy)}
      description={getCopy('desc.newsletterUnsubscribe', microcopy)}
      slug={'newsletter/unsubscribe'}
    >
      <Container className={styles.newsletterWrapper}>
        <Headline
          level="h1"
          type="h1"
          text={getCopy('newsletter.unsubscribeHeadline', microcopy)}
        />
        <iframe
          src="https://scnem.com/art_resource.php?sid=8jdke.15qln31"
          width="100%"
          height="170"
          frameborder="0"
        ></iframe>
      </Container>
    </Layout>
  );
};

export default NewsletterUnsubscribe;
